import React from "react"
import { Link, graphql } from "gatsby"
import styled from "styled-components"
import moment from "moment"
import "moment/locale/ko";

import { Gym, GymDifficulties, ProblemConnection, Problem, SolutionConnection } from "@types"
import GymCoverImage from "@/gym/components/GymCoverImage"
import Breadcrumb from "@/components/Breadcrumb"

interface Props {
  data: {
    gym: Gym
    allProblem: ProblemConnection
    allSolution: SolutionConnection
  }
}

export default (props: Props) => {
  const { gym, allProblem, allSolution } = props.data

  const problemSections = gym.difficulties!.map(difficulty => (
    <ProblemSection key={difficulty!.id!} difficulty={difficulty!} allProblem={allProblem} allSolution={allSolution} />
  ))

  return (
    <Container>
      <GymCoverImage imageName={props.data.gym.image!} />
      <Section>
        <Breadcrumb>
          <Link to="/">홈</Link>
          <Link to="#">{props.data.gym.name}</Link>
        </Breadcrumb>
        <Title>{props.data.gym.name}</Title>
      </Section>
      <Section>
        <h2 className="subtitle">문제들</h2>
        {problemSections}
      </Section>
    </Container>
  )
}

export const query = graphql`
  query($gymId: String!) {
    gym: gym(id: { eq: $gymId }) {
      id
      name
      image
      difficulties {
        id
        name
        color
      }
    }
    allProblem: allProblem(filter: { gym_id: { eq: $gymId } }, sort: { fields: setAt, order: DESC }) {
      edges {
        node {
          id
          gym_id
          name
          image
          difficulty_id
          setAt
        }
      }
    }
    allSolution: allSolution {
      edges {
        node {
          id
          problem_id
        }
      }
    }
  }
`

const DifficultyTag = (props: { color: string }) => (
  <span
    className="tag"
    style={{
      backgroundColor: props.color,
      width: "20px",
      height: "20px",
      borderRadius: "10px",
      marginRight: "10px",
    }}
  />
)

const ProblemSection = (props: { difficulty: GymDifficulties, allProblem: ProblemConnection, allSolution: SolutionConnection }) => {
  const { difficulty, allProblem, allSolution } = props

  const problemListItems = allProblem.edges
    .filter(edge => edge.node.difficulty_id === difficulty.id)
    .map(edge => <ProblemListItem key={edge.node.id} problem={edge.node} allSolution={allSolution} />)

  return (
    <ProblemSectionContainer>
      <ProblemSectionTitle>
        <DifficultyTag color={difficulty.color!} /> {difficulty!.name}
      </ProblemSectionTitle>
      <Carousel>
        {problemListItems.length ? problemListItems : "준비중입니다."}
      </Carousel>
    </ProblemSectionContainer>
  )
}

const ProblemListItem = (props: { problem: Problem, allSolution: SolutionConnection }) => {
  const formattedSetAt = moment(props.problem.setAt!).locale('ko').calendar().split(' ', 2).join(' ')
  const solutionCount = props.allSolution.edges.filter(edge => edge.node.problem_id === props.problem.id).length
  return (
    <CarouselItem key={props.problem.id}>
      <Link to={`/gyms/${props.problem.gym_id}/problems/${props.problem.id}`}>
        <img src={`/images/problems/${props.problem.image}`} alt={props.problem.name!} />
        <ProblemName>{props.problem.name}</ProblemName>
        <ProblemSetAt>{formattedSetAt} 세팅</ProblemSetAt>
        <ProblemSolutionCount>{solutionCount}개의 영상</ProblemSolutionCount>
      </Link>
    </CarouselItem>
  )
}

const Container = styled.div.attrs({ className: 'container' })`
`

const Section = styled.section.attrs({ className: 'section' })`
`

const ProblemSectionContainer = styled.div`
  &:not(:last-child) {
    margin-bottom: 30px;
  }
`

const ProblemSectionTitle = styled.h3`
  margin-bottom: 20px;
  font-weight: bold;

  display: flex;
  flex-direction: row;
  align-items: center;
`

const Title = styled.h1.attrs({ className: 'title' })`
`

const Carousel = styled.div`
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
  padding-bottom: 20px;
`

const CarouselItem = styled.div`
  display: inline-block;

  img {
    width: 200px;
    height: 200px;
    border-radius: 10px;
  }

  &:not(:last-child) {
    margin-right: 15px;
  }
`

const ProblemName = styled.div`
  color: var(--black);
`

const ProblemSetAt = styled.div`
  color: var(--grey-light);
  font-size: var(--size-7);
`

const ProblemSolutionCount = styled.div`
  color: var(--grey);
  font-size: var(--size-7);
`
